import React, { useState, useEffect, ChangeEvent } from "react"
import { graphql } from "gatsby"

import PublicationCard from "../components/PublicationCard"
import PublicationSelector from "../components/PublicationSelector"
import SearchInput from "../components/SearchInput"

const pubTypes = [
  "all publications",
  "journal article",
  "technical report",
  "proceedings paper",
  "research product",
  "other report",
]

type PubColorsType = {
  gradientFrom: string
  gradientTo: string
  solid: string
}

const getPubColors = (publicationType: string) => {
  let pubColors: PubColorsType
  switch (publicationType) {
    case "all publications":
      pubColors = {
        gradientFrom: "from-gray-500",
        gradientTo: "to-gray-600",
        solid: "gray-600",
      }
      break
    case "journal article":
      pubColors = {
        gradientFrom: "from-green-300",
        gradientTo: "to-green-500",
        solid: "green-500",
      }
      break
    case "technical report":
      pubColors = {
        gradientFrom: "from-blue-400",
        gradientTo: "to-blue-600",
        solid: "blue-600",
      }
      break
    case "research product":
      pubColors = {
        gradientFrom: "from-yellow-300",
        gradientTo: "to-yellow-500",
        solid: "yellow-500",
      }
      break
    case "proceedings paper":
      pubColors = {
        gradientFrom: "from-red-400",
        gradientTo: "to-red-600",
        solid: "red-600",
      }
      break
    case "other report":
      pubColors = {
        gradientFrom: "from-purple-600",
        gradientTo: "to-purple-700",
        solid: "purple-700",
      }
      break
  }
  return pubColors
}

const PublicationsPage = ({ navigate, data }) => {
  const [pubSearchInput, setPubSearchInput] = useState("")
  const [selectedPubData, setSelectedPubData] = useState(
    data.allStothartPublicationsJson.nodes
  )
  const [selectedPubCount, setSelectedPubCount] = useState(0)

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPubSearchInput(event.target.value)
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.location.search) {
        try {
          let query = window.location.search.split("=")[1]
          query = decodeURI(query)
          setPubSearchInput(query)
        } catch {}
      }
    }
  }, [])

  useEffect(() => {
    const pubSearchInputArray = pubSearchInput.toLowerCase().trim().split(" ")

    const newSelectedPubData = data.allStothartPublicationsJson.nodes.filter(
      pub => {
        const authors = data.allStothartCollaboratorsJson.nodes.filter(
          author => {
            return pub.collaborators.includes(author.id)
          }
        )

        let inputWordsFound = 0

        for (const inputWord of pubSearchInputArray) {
          let inputWordFound = false
          for (const property in pub) {
            if (pub[property] && property !== "publisherUrl") {
              const propertyValue = pub[property].toString().toLowerCase()
              if (propertyValue.includes(inputWord)) {
                inputWordFound = true
              }
            }

            if (property === "mediaCoverage" && pub[property]) {
              if ("featured in".includes(inputWord)) {
                inputWordFound = true
              }
              for (const article of pub[property]) {
                const publisher = article.publisher.toString().toLowerCase()
                if (publisher.includes(inputWord)) {
                  inputWordFound = true
                }
              }
            }

            if (property === "year" && pub[property] === 999) {
              if ("in press".includes(inputWord)) {
                inputWordFound = true
              }
            }
          }

          for (const author of authors) {
            for (const authorProperty in author) {
              if (author[authorProperty]) {
                if (
                  author[authorProperty]
                    .toString()
                    .toLowerCase()
                    .includes(inputWord)
                ) {
                  inputWordFound = true
                }
              }
            }
          }

          if (inputWordFound) inputWordsFound += 1
        }

        let pubTypeFound = null
        for (const pubType of pubTypes) {
          if (pubSearchInput.toLowerCase().trim().includes(pubType)) {
            pubTypeFound = pubType
          }
        }

        if (pubTypeFound) {
          if (pubTypeFound === pub.publicationType) {
            return inputWordsFound === pubSearchInputArray.length ? true : false
          }
        } else {
          return inputWordsFound === pubSearchInputArray.length ? true : false
        }
      }
    )

    setSelectedPubData(newSelectedPubData)
    setSelectedPubCount(newSelectedPubData.length)

    if (pubSearchInput.trim().length > 0) {
      navigate(`?q=${pubSearchInput}`)
    } else {
      navigate("/publications")
    }
  }, [pubSearchInput])

  const publicationSelectors = pubTypes.map(pubType => {
    let isOn = false
    if (pubType === "all publications") {
      isOn = pubSearchInput.trim() === "" ? true : false
    } else {
      isOn = pubSearchInput.toLowerCase().includes(pubType) ? true : false
    }

    const pubColors = getPubColors(pubType)
    return (
      <PublicationSelector
        key={pubType}
        selectorString={pubType}
        pubColors={pubColors}
        onClick={() => {
          if (pubType === "all publications") {
            setPubSearchInput("")
            navigate("")
          } else {
            setPubSearchInput(pubType)
            navigate(`?q=${pubType}`)
          }
        }}
        on={isOn}
        extraStyle="md:mr-3"
      />
    )
  })

  const publicationSectionContent = selectedPubData.map(pub => {
    const authors = pub.collaborators.map(pubAuthorId => {
      let authorObject = {}
      for (const author of data.allStothartCollaboratorsJson.nodes) {
        if (pubAuthorId === author.id) {
          authorObject = author
        }
      }
      return authorObject
    })

    const pubColors = getPubColors(pub.publicationType)
    return (
      <PublicationCard
        key={pub.id}
        title={pub.title}
        subtitle={pub.subtitle ? pub.subtitle : null}
        publisher={pub.publisher}
        publisherUrl={pub.publisherUrl}
        publicationType={pub.publicationType}
        year={pub.year}
        authors={authors}
        doi={pub.doi}
        mediaCoverage={pub.mediaCoverage}
        pubColors={pubColors}
      />
    )
  })

  return (
    <>
      <div className="pt-6 pb-6">
        <SearchInput
          label="Search Publications"
          handleInputChange={handleInputChange}
          searchInput={pubSearchInput}
          searchResultCount={selectedPubCount}
        />
      </div>

      <div className="mb-9 mt-3 grid gap-3 grid-cols-2 sm:grid-cols-3 md:gap-0 md:flex md:flex-wrap">
        {publicationSelectors}
      </div>

      <div className="space-y-3 mt-3">
        {selectedPubCount > 0 ? (
          publicationSectionContent
        ) : (
          <p>No publications found.</p>
        )}
      </div>
    </>
  )
}

export const query = graphql`
  query {
    allStothartPublicationsJson {
      nodes {
        id
        publicationType
        publisher
        publisherUrl
        subtitle
        title
        year
        collaborators
        doi
        mediaCoverage {
          publisher
          url
        }
      }
    }
    allStothartCollaboratorsJson {
      nodes {
        id
        familyName
        firstName
        middleInitial
      }
    }
  }
`

export default PublicationsPage
