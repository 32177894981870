import * as React from "react"

import CardExternalLink from "./CardExternalLink"
import ExternalLink from "./ExternalLink"

const PublicationCard = ({
  title,
  subtitle,
  publisher,
  publisherUrl,
  publicationType,
  year,
  authors,
  doi,
  mediaCoverage,
  pubColors,
}) => {
  const authorList = authors.map(author => {
    let fullName: string
    if (author.middleInitial) {
      fullName = `${author.firstName} ${author.middleInitial}. ${author.familyName}`
    } else {
      fullName = `${author.firstName} ${author.familyName}`
    }
    const authorStyle =
      author.familyName.toLowerCase() === "stothart"
        ? "font-bold capitalize mr-3"
        : "capitalize mr-3"
    return (
      <div className={authorStyle} key={fullName}>
        {fullName}
      </div>
    )
  })

  const mediaCoverageList = !mediaCoverage
    ? null
    : mediaCoverage.map(article => {
        return (
          <div key={article.url} className="mr-3">
            <ExternalLink href={article.url}>{article.publisher}</ExternalLink>
          </div>
        )
      })

  const mergedTitle = subtitle ? `${title}: ${subtitle}` : title

  return (
    <article className="flex card">
      <div
        style={{ width: "5px" }}
        className={`bg-gradient-to-bl ${pubColors.gradientFrom} ${pubColors.gradientTo} rounded-l-lg`}
      ></div>
      <div className="flex flex-col p-3 space-y-2 w-full rounded-r-lg">
        <div className="flex justify-end text-xs">
          <div className="capitalize flex-grow text-gray-700">
            {publicationType}
          </div>
          <div>{year === 999 ? "In Press" : year}</div>
        </div>
        <cite className="font-bold not-italic capitalize leading-tight">
          {mergedTitle}
        </cite>
        <div className="text-xs capitalize leading-tight text-gray-700">
          {publisher}
        </div>
        <div className="flex flex-wrap text-xs text-gray-700">{authorList}</div>

        {mediaCoverageList && (
          <div className="flex flex-wrap text-xs text-gray-700">
            <div className="mr-3 font-bold">Featured in:</div>{" "}
            {mediaCoverageList}
          </div>
        )}

        <div className="flex space-x-4 items-center justify-end w-full">
          {publisherUrl ? (
            <CardExternalLink url={publisherUrl} label="Publication Page" />
          ) : null}
        </div>
      </div>
    </article>
  )
}

export default PublicationCard
