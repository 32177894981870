import * as React from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"

const CardExternalLink = ({ url, label }) => (
  <OutboundLink href={url} target="_blank">
    <div className="flex border text-gray-500 border-gray-300 p-1 rounded-lg hover:text-gray-800 hover:border-gray-600 transition duration-150">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-4 w-4 mr-1"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
        />
      </svg>
      <div className="text-xs">{label}</div>
    </div>
  </OutboundLink>
)

export default CardExternalLink
