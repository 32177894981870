import * as React from "react"

type PubColorsType = {
  gradientFrom: string
  gradientTo: string
  solid: string
}

type PublicationSelectorType = {
  selectorString: string
  pubColors: PubColorsType
  onClick: () => void
  on: boolean
  extraStyle?: string
}

const PublicationSelector = ({
  selectorString,
  pubColors,
  onClick,
  on,
  extraStyle,
}: PublicationSelectorType) => {
  const statusStyles = {
    on: `bg-gradient-to-bl ${pubColors.gradientFrom} ${pubColors.gradientTo} text-white`,
    off: `bg-gray-300 md:hover:bg-gray-200`,
  }
  const statusStyle = on ? statusStyles.on : statusStyles.off

  return (
    <button
      onClick={onClick}
      className={`focus:outline-none select-none shadow-sm flex justify-center capitalize rounded-lg text-xs p-2 font-bold cursor-pointer ${statusStyle} md:transition md:duration-150 ${extraStyle}`}
    >
      {selectorString}
    </button>
  )
}

export default PublicationSelector
