import React, { ChangeEvent } from "react"

type SearchInputType = {
  label: string
  handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void
  searchInput: string
  searchResultCount: number
}

const SearchInput = ({
  handleInputChange,
  searchInput,
  searchResultCount,
  label,
}: SearchInputType) => (
  <div className="flex justify-end items-center input-wrapper min-w-full sm:min-w-min">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5 text-gray-400 mr-2"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
      />
    </svg>
    <input
      type="search"
      className="input"
      placeholder={label}
      aria-label={label}
      onChange={handleInputChange}
      value={searchInput}
    />
    <div className="text-gray-400 ml-3">{searchResultCount}</div>
  </div>
)

export default SearchInput
